import React from 'react'
import { Helmet } from 'react-helmet'
import CTA from '../components/CTA/cta'

const Unsub = () => (<>
    <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
    <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '88px 2rem 2rem' }}>
        You have been unsubscribed. You will no longer receive updates from Tearribles.<br /><br />
        <CTA title="Go to Home Page" to="/" />
    </p>
</>)

export default Unsub
